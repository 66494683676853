import anime from "animejs";

anime({
    targets: '#intro h1, #intro h2',
    translateY: [-50, 0],
});

anime({
    targets: 'nav .linkDivContainer',
    translateY: [-50, 0],
    delay: anime.stagger(100)
});

anime({
    targets: 'article',
    translateX: [-100, 0],
    delay: anime.stagger(100)
});

navLinks = document.querySelectorAll("nav .linkDivContainer");

navLinks.forEach((element) => {
    let linkElement = element.querySelector("a");
    console.log(linkElement);
    linkElement.addEventListener("mouseover", (event) => {
        anime({
            targets: linkElement,
            translateY: 10,
            duration: 1000
        });
        element.querySelector(".buttonBg").style.display = "block";
    })
    linkElement.addEventListener("mouseleave", (event) => {
        anime({
            targets: linkElement,
            translateY: 0,
            duration: 1000
        });
        element.querySelector(".buttonBg").style.display = "none";
    })
});
